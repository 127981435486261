<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
            <em>当前位置：</em>
            <a href="javascript:;">数据中心</a>
            <i>></i>
            <a href="javascript:;" class="cur-a">公示名单</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="
            operationControl
            flexdc
            operationControlCurr
            operationControlWidth
          "
          style="align-items: flex-start"
        >
        <div class="searchbox" style="width: 100%;margin-bottom: 15px;">
            <div>
              <div title="公示区域" class="searchboxItem searchboxItems">
                <span class="itemLabel">公示区域:</span>
                <el-cascader
                  clearable
                  v-model="ruleForm.publicityAreaId"
                  placeholder="请选择公示区域"
                  :options="areatreeList"
                  :props="propsarea"
                  size="small"
                ></el-cascader>
              </div>
              <div title="公示开始时间" class="searchboxItem searchboxItems">
                <span class="itemLabel" style="min-width:7rem">公示开始时间:</span>
                <el-date-picker
                    v-model="ruleForm.startDate"
                    placeholder="请选择公示开始时间"
                    type="daterange"
                    size="small"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                </el-date-picker>
              </div>
              <div title="公示结束时间" class="searchboxItem searchboxItems">
                <span class="itemLabel" style="min-width:7rem">公示结束时间:</span>
                <el-date-picker
                    v-model="ruleForm.endDate"
                    placeholder="请选择公示结束时间"
                    type="daterange"
                    size="small"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                </el-date-picker>
              </div>
            </div>
        </div>
          <div class="searchbox" style="width: 100%;;">
            <div>
              <div title="企业名称" class="searchboxItem searchboxItems">
                <span class="itemLabel">企业名称:</span>
                <el-input
                    v-model="ruleForm.publicityCompanyName"
                    type="text"
                    clearable
                    size="small"
                    placeholder="请输入企业名称"
                />
              </div>
              <div title="是否代理企业" class="searchboxItem searchboxItems">
                <span class="itemLabel">是否代理企业:</span>
                <el-select 
                    v-model="ruleForm.publicityIsAgency" 
                    placeholder="请选择是否代理企业"
                    clearable
                    size="small"
                >
                  <el-option label="是" :value="true"></el-option>
                  <el-option label="否" :value="false"></el-option>
                </el-select>
              </div>
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="
                  () => {
                    getData();
                  }
                "
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              :cell-class-name="cellClassName"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="公示区域"
                align="left"
                show-overflow-tooltip
                prop="areaName"
                min-width="150"
              />
              <el-table-column
                label="公示名称"
                align="left"
                show-overflow-tooltip
                prop="publicityName"
                min-width="150"
              />
              <el-table-column label="业务年度" align="left" show-overflow-tooltip prop="businessYearStr" min-width="100" />
              <el-table-column
                label="公示日期"
                align="left"
                show-overflow-tooltip
                prop="publicityDate"
                min-width="150"
              >
                <template slot-scope="{row}">
                    {{row.publicityDateStart}} - {{row.publicityDateEnd}}
                </template>
              </el-table-column>
              <el-table-column
                label="企业名称"
                align="left"
                show-overflow-tooltip
                prop="publicityCompanyName"
                min-width="150"
              />
              <el-table-column label="项目名称" align="left" show-overflow-tooltip prop="publicityItemName" min-width="150" />
              <el-table-column
                label="人数"
                align="left"
                show-overflow-tooltip
                prop="publicitySubsidyPersonNum"
                min-width="100"
              />
              <el-table-column
                label="金额"
                align="left"
                show-overflow-tooltip
                prop="publicitySubsidyAmount"
                min-width="100"
              />
              <el-table-column
                label="是否代理企业"
                align="left"
                show-overflow-tooltip
                prop="publicityIsAgency"
                min-width="100"
              >
              <template slot-scope="{ row }">
                    {{row.publicityIsAgency?'是':''}}
              </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "jgpublicityQuery",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      ruleForm: {
        publicityAreaId: "",//公示区域
        startDate: "",//公示开始日期
        endDate: "",//公示结束日期
        publicityCompanyName: "",//企业名称
        publicityIsAgency: "",//是否代理企业
      },
      areatreeList:[],//公示区域list
      propsarea: {
        value: "value",
        label: "label",
        disabled: "disabled",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  created() {
    this.getareatree()
  },
  computed: {},
  mounted() {},
  methods: {
    cellClassName({row,column}){
        if(column.property == 'publicityIsAgency'&&row.publicityIsAgency){
            return 'red'
        }
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        let data = ret.data || [];
        data.map((el) => {
          if (el.children) {
            el.children.map((els) => {
              els.disabled = false;
              if (els.children) {
                els.children.map((els1) => {
                  els1.disabled = false;
                });
              }
            });
          }
        });
        this.areatreeList = data;
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        publicityIsAgency: this.ruleForm.publicityIsAgency,//是否代理企业
      };
      // 公示区域
      if (this.ruleForm.publicityAreaId) {
        params.publicityAreaId = this.ruleForm.publicityAreaId;
      }
      // 企业名称
      if (this.ruleForm.publicityCompanyName) {
        params.publicityCompanyName = this.ruleForm.publicityCompanyName;
      }
      // 公示开始日期
      if (this.ruleForm.startDate?.length>0) {
        params.publicityStartDateStart = this.ruleForm.startDate[0];
        params.publicityStartDateEnd = this.ruleForm.startDate[1];
      }
      // 公示结束日期
      if (this.ruleForm.endDate?.length>0) {
        params.publicityEndDateStart = this.ruleForm.endDate[0];
        params.publicityEndDateEnd = this.ruleForm.endDate[1];
      }
      this.doFetch(
        {
          url: "/biz/new/publicity/companyPageListAgency",
          params,
          pageNum,
        },
        true
      );
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.searchbox > div .el-input {
  flex: 1;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}

</style>
<style lang="less" scoped>
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.Classitem {
  .el-form-item__content {
    display: flex;
  }
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}
.studentNum {
  margin: 20px 0 10px;
  span {
    margin: 0 10px;
  }
}
.exportdialog {
  /deep/.upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
/deep/ .red{
    color: red;
}
</style>

